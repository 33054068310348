<template>
  <a-button
    v-bind:style="{ height, borderRadius }"
    class="cuy-button"
    v-on:click="onSubmit"
    >{{ title }}</a-button
  >
</template>

<script>
export default {
  name: "CuyButton",
  props: ["onSubmit", "title", "borderRadius", "height"]
};
</script>

<style lang="less">
.cuy-button {
  border: 1.5px solid white;
  height: 50px;
  font-size: 20px;
  background-color: #132d2f;
  color: white;
  font-family: "Bungee", cursive;
  cursor: pointer;
  box-shadow: 0px 2px 10px rgba(14, 17, 26, 0.12);
  place-self: center;
}

.cuy-button:focus,
.cuy-button:hover {
  color: #132d2f !important;
  border-color: #132d2f !important;
}
</style>