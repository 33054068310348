<template>
  <div class="complaints static">
    <div class="complaints-book">
      <div class="complaints-book-left">
        <a href="/">
          <a-icon type="left" />Home
        </a>
        <h2 class="title">
          Libro de
          Reclamaciones
        </h2>

        <div class="complaints-book-img">
          <img src="../../assets/vision/layout_icon@2x.png" alt />
          <h3>
            Guinea Mobile SAC
            <br />RUC: 20161466075
          </h3>
        </div>
        <div></div>
      </div>
      <div class="form">
        <div class="form-group">
          <h2>Identificación del consumidor reclamante</h2>
          <div class="form-group-grid">
            <div class="form-item">
              <label>Nombre</label>
              <a-input v-model="name" />
            </div>
            <div class="form-item">
              <label>Apellido</label>
              <a-input v-model="lastName" />
            </div>
            <div class="form-item">
              <label>Teléfono con eXIM</label>
              <a-input v-model="phone" />
            </div>
            <div class="form-item">
              <label>E-mail</label>
              <a-input v-model="email" />
            </div>
            <div class="form-item">
              <label>DNI</label>
              <a-input v-model="dni" />
            </div>
            <div class="form-item">
              <label>Domicilio</label>
              <a-input v-model="address" />
            </div>
          </div>
        </div>
        <div class="form-group">
          <h2>Identificación del bien contratado</h2>
          <div class="form-group-grid">
            <div class="form-item">
              <label>Producto o servicio</label>
              <a-select v-model="productOrService">
                <a-select-option value="Producto">Producto</a-select-option>
                <a-select-option value="Servicio">Servicio</a-select-option>
              </a-select>
            </div>
            <div class="form-item">
              <label>Descripción</label>
              <a-input v-model="description" />
            </div>
            <div class="form-item">
              <label>Monto reclamado</label>
              <a-input type="number" v-model="reclaimedAmount" />
            </div>
          </div>
        </div>
        <div class="form-group">
          <h2>Detalle de la reclamación</h2>
          <div class="form-group-grid">
            <div class="form-item">
              <label>Reclamo o queja</label>
              <a-select placeholder="Selecciona" v-model="claim">
                <a-select-option value="Reclamo">Reclamo</a-select-option>
                <a-select-option value="Queja">Queja</a-select-option>
              </a-select>
            </div>
            <div class="form-item">
              <label>Tipo de reclamo o queja</label>
              <a-select placeholder="Selecciona" v-model="claimType">
                <a-select-option value="Infraestructura">Infraestructura</a-select-option>
                <a-select-option
                  value="Características del servicio o producto"
                >Características del servicio o producto</a-select-option>
                <a-select-option value="Trato del personal">Trato del personal</a-select-option>
                <a-select-option value="Otro reclamo">Otro reclamo</a-select-option>
              </a-select>
            </div>
            <div class="form-item">
              <label>Detalle</label>
              <a-input v-model="detail" />
            </div>
            <div class="form-item">
              <label>Pedido</label>
              <a-input v-model="order" />
            </div>
          </div>
        </div>
        <CuyButton :onSubmit="sendForm" title="Enviar" />
      </div>
    </div>
  </div>
</template>

<script>
import CuyButton from "../../components/CuyButton";
import { mapActions } from "vuex";

export default {
  name: "ComplaintsBook",
  props: {},
  components: {
    CuyButton
  },
  data() {
    return {
      name: "",
      lastName: "",
      dni: "",
      phone: "",
      email: "",
      address: "",
      productOrService: "",
      reclaimedAmount: "",
      description: "",
      claim: "",
      detail: "",
      claimType: "",
      order: ""
    };
  },
  methods: {
    ...mapActions(["sendBook"]),
    validate() {
      if (
        this.name == "" ||
        this.lastName == "" ||
        this.dni == "" ||
        this.phone == "" ||
        this.email == "" ||
        this.address == "" ||
        this.productOrService == "" ||
        this.reclaimedAmount == "" ||
        this.description == "" ||
        this.claim == "" ||
        this.detail == "" ||
        this.claimType == "" ||
        this.order == ""
      ) {
        this.$message.error("Por favor, llene todos los campos");
        return false;
      }

      if (this.dni.length < 8) {
        this.$message.error("El dni debe tener al menos 8 caracteres");
        return false;
      }

      if (!this.validateEmail(this.email)) {
        this.$message.error("Ingrese un email correcto");
        return false;
      }
      return true;
    },

    validateEmail(email) {
      var re = new RegExp(
        "[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?"
      );
      return re.test(email);
    },
    resetForm() {
      this.name = "";
      this.lastName = "";
      this.dni = "";
      this.phone = "";
      this.email = "";
      this.address = "";
      this.productOrService = "";
      this.reclaimedAmount = "";
      this.description = "";
      this.claim = "";
      this.detail = "";
      this.claimType = "";
      this.order = "";
    },
    async sendForm() {
      if (this.validate()) {
        const payload = {
          name: this.name,
          lastName: this.lastName,
          dni: this.dni,
          phone: this.phone,
          email: this.email,
          address: this.address,
          productOrService: this.productOrService,
          reclaimedAmount: this.reclaimedAmount,
          description: this.description,
          claim: this.claim,
          detail: this.detail,
          claimType: this.claimType,
          order: this.order
        };

        try {
          this.loading = true;
          await this.sendBook(payload);
        } catch (error) {
          this.$message.error(`Algo salio mal, vuelva a intentar`);
          return;
        }
        this.loading = false;
        this.$message.success("Su reclamo ha sido enviado correctamente");
        this.resetForm();
      }
    }
  }
};
</script>

<style lang="less">
.form {
  display: flex;
  flex-direction: column;

  .ant-select-arrow,
  .ant-select-selection__placeholder {
    color: #6a3091;
  }
  .ant-select-selection {
    border-color: #6a3091;
  }
  .cuy-button {
    margin-top: 3rem;
  }
  &-group {
    margin-top: 1rem;
    &-grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1.4rem 2rem;
    }
    h2 {
      font-family: "Bungee", cursive;
    }
  }
  &-item {
    display: flex;
    flex-direction: column;
    label {
      font-weight: 600;
      color: #6a3091;
      margin-bottom: 10px;
    }
  }

  .ant-input {
    border-color: #6a3091;
  }
  .ant-input:focus,
  .ant-input:hover {
    border-right-width: 1px !important;
  }
}
.complaints {
  &-book {
    padding-left: 4rem;
    padding-right: 4rem;
    &-left {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;

      h2 {
        place-self: center;
      }
    }
    &-header {
      display: grid;
      grid-template-columns: 1fr 1fr;
      width: 100%;
    }
    &-img {
      max-width: 200px;
      display: flex;
      flex-direction: column;
      place-self: end;

      img {
        height: 100px;
        place-self: center;
      }
      h3 {
        text-align: center;
      }
    }
  }
}
</style>
